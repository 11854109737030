import "reset-css";
import "./style.css";

let isNavOpen = false;
let scrollPosition;

const functionalityNav = () => {
	const $hamburg = document.querySelector(`.list-item--hamburger`);
	const $mobileNavContainer = document.querySelector(
		`.mobile-navigation__container`
	);
	const $mobileNavigationOverlay = document.querySelector(
		`.mobile-navigation__overlay`
	);
	const $mobileNavCloseContainer = document.querySelector(
		`.mobile-navigation__close`
	);
	const $closeNavButton = document.querySelector(`.nav-close--button`);
	const $navList = document.querySelector(`.navigation__list`);
	const navListItemsMobile = document.querySelectorAll(
		`.mobile-navigation__list-item`
	);

	const heightMobileNav = $mobileNavContainer.offsetHeight;

	$hamburg.addEventListener(`click`, () => {
		if (!isNavOpen) {
			$mobileNavContainer.style.transform = `translateY(${0}px)`;
			$mobileNavContainer.style.opacity = 1;
			$mobileNavContainer.style.pointerEvents = "inherit";

			$mobileNavigationOverlay.style.backgroundColor = `rgba(0, 0, 0, 0.5)`;

			$mobileNavCloseContainer.style.pointerEvents = `inherit`;

			$closeNavButton.style.transform = `rotate(135deg) scale(1)`;
			$closeNavButton.style.opacity = 1;

			scrollPosition = window.scrollY;
			isNavOpen = true;
		}
	});

	const animateCloseNav = () => {
		$mobileNavContainer.style.transform = `translateY(-${heightMobileNav}px)`;
		$mobileNavContainer.style.opacity = 1;
		$mobileNavContainer.style.pointerEvents = "none";

		$mobileNavigationOverlay.style.backgroundColor = `rgba(0, 0, 0, 0)`;

		$mobileNavCloseContainer.style.pointerEvents = `none`;

		$closeNavButton.style.transform = `rotate(0deg) scale(0)`;
		$closeNavButton.style.opacity = 0;

		isNavOpen = false;
	};

	$closeNavButton.addEventListener(`click`, animateCloseNav);

	navListItemsMobile.forEach((item) => {
		item.addEventListener(`click`, animateCloseNav);
	});
};

const generateLessonDates = () => {
	const lessonDates24 = {
		autumn: [
			{
				month: "Sept.",
				number: 22,
			},
			{
				month: "Okt.",
				number: 6,
			},
			{
				month: "Nov.",
				number: 17,
			},
			{
				month: "Dec.",
				number: 1,
			},
			{
				month: "Dec.",
				number: 15,
			},
		],
	};

	const lessonDates25 = {
		winter: [
			{
				month: "Jan.",
				number: 12,
			},
			{
				month: "Feb.",
				number: 2,
			},
			{
				month: "Feb.",
				number: 23,
			},
			{
				month: "Mrt.",
				number: 23,
			},
			{
				month: "Mrt.",
				number: 30,
			},
		],
	};

	const containerLessonDates = document.querySelector(
		`.container__lesson-dates`
	);

	const containerCalendarYears = document.querySelectorAll(
		`.container__calendar-year`
	);
	const calendarContainer =
		containerLessonDates.querySelector(`.calendar-container`);

	const yOne = containerLessonDates.querySelector(`.y-one`);
	const yTwo = containerLessonDates.querySelector(`.y-two`);

	const zigzagOne = yOne.querySelector(`.calendar-year__zigzag`);
	const zigzagTwo = yTwo.querySelector(`.calendar-year__zigzag`);

	const seasonShowDiv = containerLessonDates.querySelector(`.season--only-one`);
	const seasonText = containerLessonDates.querySelector(`.season--text`);

	const infoSubscribe = containerLessonDates.querySelector(`.info-subscribe`);

	containerCalendarYears.forEach((calendarYear) => {
		calendarYear.addEventListener(`click`, () => {
			if (
				calendarYear.classList.contains(`y-one`) &&
				calendarYear.classList.contains(`year--disabled`)
			) {
				calendarYear.classList.remove(`year--disabled`);
				zigzagOne.classList.remove(`hidden`);

				yTwo.classList.add(`year--disabled`);
				zigzagTwo.classList.add(`hidden`);

				// change Dates and Season

				seasonText.textContent = `Herfst`;
				seasonShowDiv.classList.remove(`season-btn-bg--winter`);
				seasonShowDiv.classList.add(`season-btn-bg--autumn`);

				calendarContainer.innerHTML = ``;

				lessonDates24.autumn.forEach((date) => {
					calendarContainer.innerHTML += `
					<div class="calendar-day">
						<div class="calendar-day__month">${date.month}</div>
						<p class="calendar-day__number">${date.number}</p>
					</div>`;
				});

				infoSubscribe.innerHTML = `De inschrijvingen voor de herfstreek 2024 zijn afgesloten. Je kan je binnenkort inschrijven voor de winterreeks van 2025!`;
			}

			if (
				calendarYear.classList.contains(`y-two`) &&
				calendarYear.classList.contains(`year--disabled`)
			) {
				calendarYear.classList.remove(`year--disabled`);
				zigzagTwo.classList.remove(`hidden`);

				yOne.classList.add(`year--disabled`);
				zigzagOne.classList.add(`hidden`);

				// change Dates and Season

				seasonText.textContent = `Winter`;
				seasonShowDiv.classList.remove(`season-btn-bg--autumn`);
				seasonShowDiv.classList.add(`season-btn-bg--winter`);

				calendarContainer.innerHTML = ``;

				lessonDates25.winter.forEach((date) => {
					calendarContainer.innerHTML += `
					<div class="calendar-day">
						<div class="calendar-day__month">${date.month}</div>
						<p class="calendar-day__number">${date.number}</p>
					</div>`;
				});

				infoSubscribe.innerHTML = `
				De inschrijvingen voor de winterstreeks voor de kinderen die reeds deelnamen aan de vorige reeks gaan open op
				<span class="p-bold">1 december om 10u.</span>
				<br />
				De inschrijvingen voor de <span class="p-bold">nieuwe</span> kids gaan open op <span class="p-bold">8 december om 10u</span>.`;
			}
		});
	});

	containerLessonDates.querySelector(`.calendar-year__two`);
};

const init = () => {
	functionalityNav();
	generateLessonDates();
};

init();
